export default {
  data: () => ({
    loading: true,
    fields: [
      {
        key: 'pos',
        label: 'id'
      },
      {
        key: 'option',
        label: 'Pacote'
      },
      {
        key: 'actions',
        label: 'Baixar'
      }
    ],
    items: []
  }),
  methods: {
    async getAccounts () {
      const url = '/user/services/my_purchases/pack_of_photos'
      await this.$http.get(url)
        .then(res => {
          this.items = res.data.map((item, index) => {
            return {
              ...item,
              pos: index + 1
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  async mounted () {
    await this.getAccounts()
  }
}
